import initialState from './state'

export default {
  RESET_STORE: (state) => {
    Object.assign(state, initialState())
  },

  SET_AUTH_USER: (state, { user, claims }) => {
    state.authUser = {
      uid: user.uid,
      email: user.email,
      emailVerified: user.emailVerified,
      photoURL: user.photoURL || null,
      displayName: user.displayName,
      allClaims: claims,
      providerId: user.providerData[0].providerId,
    }
  },
  setUser: (state, user) => {
    state.user = user
  },
  // setUserMenuItems: (state, action) => {
  //   if (action === 'createGraduate') {
  //     state.userMenuItems.unshift({
  //       text: 'Create Profile',
  //       icon: 'mdi-account-plus',
  //       route: '/account/create/',
  //     })
  //   }
  //   if (action === 'displayGraduate') {
  //     state.userMenuItems.splice(1, 0, {
  //       text: 'My Graduate Profile',
  //       icon: 'mdi-account-tie',
  //       route: '/account/graduate/',
  //     })
  //   }
  // },
  // deleteCreateProfileItem: (state) => {
  //   state.userMenuItems.shift()
  //   state.userMenuItems.splice(1, 0, {
  //     text: 'My Graduate Profile',
  //     icon: 'mdi-account-tie',
  //     route: '/account/graduate/',
  //   })
  // },
  // deleteGraduateProfileItem: (state) => {
  //   state.userMenuItems = state.userMenuItems.filter(
  //     (item) => item.text !== 'My Graduate Profile'
  //   )
  //   state.userMenuItems.unshift({
  //     text: 'Create Profile',
  //     icon: 'mdi-account-plus',
  //     route: '/account/create/',
  //   })
  // },

  SET_SCHOOLS: (state, schools) => {
    state.schools = schools
  },
  SHOW_MESSAGE: (state, payload) => {
    state.snackbar.content = payload.content
    state.snackbar.color = payload.color
  },
}
