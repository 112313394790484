
import { mapState, mapGetters } from 'vuex'
export default {
  props: {
    user: {
      type: Object,
    },
  },
  emits: ['log-out'],
  data() {
    return {
      userMenu: false,
    }
  },
  computed: {
    ...mapState({
      authUser: (state) => state.authUser,
    }),
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      userMenuItems: 'userMenuItems',
      emailVerified: 'emailVerified',
      isAdmin: 'isAdmin',
      isGraduate: 'isGraduate',
      isIndustry: 'isIndustry',
    }),
    role() {
      if (this.authUser?.allClaims?.stripeRole) {
        if (this.authUser.allClaims.stripeRole == null) {
          return 'free'
        } else {
          return this.authUser.allClaims.stripeRole
        }
      } else if (this.authUser?.allClaims.admin) {
        return 'admin'
      } else if (this.authUser) {
        return 'free'
      } else {
        return 'free'
      }
    },
  },
  mounted() {},
}
