export default function ({ route, store, error, redirect }) {
  if (store.state.authUser) {
    const user = store.state.authUser
    //Hubspot identity
    var _hsq = (window._hsq = window._hsq || [])
    _hsq.push([
      'identify',
      {
        email: user.email,
        id: user.uid,
      },
    ])
    _hsq.push(['setPath', `/#${route.path}`])
    // Track the page view for the new page
    _hsq.push(['trackPageView'])
  }
  if (!store.state.authUser) {
    error({
      message: 'You are not logged in',
      statusCode: 403,
    })
    return redirect('/login/')
    // console.log("not logged in");
  }
}
