
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      emailVerified: 'emailVerified',
      isAdmin: 'isAdmin',
      isGraduate: 'isGraduate',
      isIndustry: 'isIndustry',
    }),
    menuItems() {
      if (!this.isLoggedIn) return this.loggedOutMenu
      if (this.isLoggedIn && !this.isIndustry && !this.isGraduate)
        return this.freeMenu
      if (this.isGraduate) return this.graduateMenu
      if (this.isIndustry) return this.industryMenu
      return this.loggedOutMenu
    },
  },
  data() {
    return {
      loggedOutMenu: [
        { text: 'Grads', icon: 'mdi-account-group-outline', to: '/graduates/' },
        { text: 'Schools', icon: 'mdi-school-outline', to: '/schools/' },
        { text: 'Hub', icon: 'mdi-help-circle-outline', to: '/info/helphub/' },
        {
          text: 'FAQs',
          icon: 'mdi-frequently-asked-questions',
          to: '/info/faq',
        },
        { text: 'Login', icon: 'mdi-login', to: '/login/' },
      ],
      freeMenu: [
        { text: 'Grads', icon: 'mdi-account-group-outline', to: '/graduates/' },
        { text: 'Schools', icon: 'mdi-school-outline', to: '/schools/' },
        { text: 'Hub', icon: 'mdi-help-circle-outline', to: '/info/helphub/' },
        {
          text: 'FAQs',
          icon: 'mdi-frequently-asked-questions',
          to: '/info/faq/',
        },
        {
          text: 'Me',
          icon: 'mdi-account-circle-outline',
          to: '/account/user/',
        },
      ],
      graduateMenu: [
        { text: 'Grads', icon: 'mdi-account-group-outline', to: '/graduates/' },
        { text: 'Schools', icon: 'mdi-school-outline', to: '/schools/' },
        { text: 'Hub', icon: 'mdi-help-circle-outline', to: '/info/helphub/' },
        {
          text: 'Me',
          icon: 'mdi-account-circle-outline',
          to: '/account/user',
        },
        { text: 'Chat', icon: 'mdi-chat', to: '/chat/' },
      ],
      industryMenu: [
        { text: 'Grads', icon: 'mdi-account-group-outline', to: '/graduates/' },
        { text: 'Schools', icon: 'mdi-school-outline', to: '/schools/' },
        {
          text: 'Me',
          icon: 'mdi-account-circle-outline',
          to: '/account/user/',
        },
        { text: 'Likes', icon: 'mdi-heart', to: '/likes/' },
        { text: 'Chat', icon: 'mdi-chat', to: '/chat/' },
      ],
    }
  },
}
