import { initializeApp } from 'firebase/app'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import { getAnalytics } from 'firebase/analytics'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getDatabase, connectDatabaseEmulator } from 'firebase/database'

const firebaseConfig = {
  apiKey: 'AIzaSyCa9kUsWtJripl9bIJFcUJ_-WaPS-t5VN4',
  authDomain: 'showcase-ddc6b.firebaseapp.com',
  databaseURL: 'https://showcase-ddc6b.firebaseio.com',
  projectId: 'showcase-ddc6b',
  storageBucket: 'images.showcasebase.com',
  messagingSenderId: '293802240032',
  appId: '1:293802240032:web:f82d2c625780596ef93445',
  measurementId: 'G-479Z3PNPCN',
}

// init firebase
let app
if (!app) {
  app = initializeApp(firebaseConfig)
}

// init services
const db = getFirestore(app)
const auth = getAuth(app)
const database = getDatabase(app)
const functions = getFunctions(app)
const storage = getStorage(app)
let analytics

const useEmulators = false

if (process.env.NODE_ENV === 'development' && useEmulators) {
  connectAuthEmulator(auth, 'http://localhost:9099')
  connectStorageEmulator(storage, 'localhost', 9199)
  connectDatabaseEmulator(database, 'localhost', 9000)
  connectFunctionsEmulator(functions, 'localhost', 5001)
  connectFirestoreEmulator(db, 'localhost', 8080)
}

if (process.browser) {
  analytics = getAnalytics(app)
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = 'c87fba99-f448-4024-8550-c3a79820e0c5'
  try {
    //get key from recaptcha https://firebase.google.com/docs/app-check/web
    const key = '6LciSk8lAAAAAP45X6h7_JMY9kHoQeNG_tVf8mIB'
    // Toggle to get APP Check debug token https://firebase.google.com/docs/app-check/web/debug-provider?authuser=0&hl=en
    initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider(key),
      // Optional argument. If true, the SDK automatically refreshes App Check
      // tokens as needed.
      isTokenAutoRefreshEnabled: true,
    })
  } catch (error) {
    // error
  }
}

const friendlyFirebaseErrors = (error) => {
  switch (error.code) {
    case 'auth/invalid-verification-code':
      return 'OTP is incorrect.'
    case 'auth/invalid-email':
    case 'auth/wrong-password':
    case 'auth/user-not-found':
      return 'Invalid email and/or password.'
    case 'auth/weak-password':
      return 'Password should be at least 6 characters.'
    case 'auth/email-already-in-use':
      return 'Email already in use.'
    case 'auth/user-disabled':
      return 'Account is disabled.'
    case 'storage/unauthorized':
      return 'File is not allowed. Less than 1mb image file only.'
    default:
      return 'Something went wrong'
  }
}

export {
  db,
  auth,
  database,
  analytics,
  app,
  functions,
  storage,
  friendlyFirebaseErrors,
}
