
export default {
  data: () => ({
    icons: [
      {
        icon: 'mdi-facebook',
        route: 'https://www.facebook.com/showcasebase',
      },
      { icon: 'mdi-twitter', route: 'https://twitter.com/showcasebase' },
      {
        icon: 'mdi-linkedin',
        route: 'https://www.linkedin.com/company/showcasebase',
      },
      {
        icon: 'mdi-instagram',
        route: 'https://www.instagram.com/showcasebasegram/',
      },
    ],
  }),
}
