import { onAuthStateChanged, getIdTokenResult } from 'firebase/auth'
import { auth } from './firebase'

export default ({ store }) => {
  return new Promise((resolve) => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Get custom claims
        const { claims } = await getIdTokenResult(auth.currentUser)
        delete claims.email
        delete claims.email_verified
        delete claims.aud
        delete claims.auth_time
        delete claims.exp
        delete claims.firebase
        delete claims.iat
        delete claims.iss
        delete claims.picture
        delete claims.sub
        delete claims.user_id
        delete claims.name
        // Commit user to store
        store.commit('SET_AUTH_USER', {
          user,
          claims,
        })
        resolve()
      } else {
        store.commit('RESET_STORE')
        resolve()
      }
    })
  })
}
