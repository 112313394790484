export default () => ({
  authUser: null,
  user: null,
  snackbar: {
    content: '',
    color: '',
  },
  regions: [
    'Anglia',
    'North East England',
    'North West England',
    'Yorkshire/Humber',
    'West Midlands',
    'East Midlands',
    'London',
    'South East England',
    'South West England',
    'Central Belt Scotland',
    'North East Scotland ',
    'Highlands and Islands',
    'Southern Scotland',
    'North Wales',
    'Mid Wales',
    'South Wales',
    'Dublin',
    'Belfast',
    'Cork',
    'Galway',
    'EU',
    'USA',
  ],
  schools: [],
  userMenuItems: [
    {
      text: 'My Graduate',
      icon: 'mdi-account-tie',
      route: '/account/graduate/',
    },
    {
      text: 'Account Information',
      icon: 'mdi-card-account-details',
      route: '/account/user/',
    },
    {
      text: 'Billing History',
      icon: 'mdi-account-cash',
      route: '/account/billing/',
    },
  ],
  courseOptions: [
    '1 Year Course',
    '1 Year Diploma in Acting',
    '2 Year Diploma in Acting',
    '2 Year Professional Actor Training',
    '2-Year Hnd Acting And Performance',
    '3 Year Course',
    '3 Year Diploma in Musical Theatre',
    '3 Year Musical Theatre Course',
    '3 Year Professional Musical Theatre Diploma',
    'Advanced Diploma in Acting',
    'Advanced intensive Acting Diploma',
    'BA (Hons) Dance',
    'BA (Hons) Professional Musical Theatre',
    'BA Acting (International)',
    'BA Acting & Community Theatre',
    'BA Acting & Contemporary Theatre',
    'BA Acting & Stage Combat',
    'BA Acting and Theatre-Making',
    'BA Actor Musicianship',
    'BA American Theatre Arts',
    'BA European Theatre Arts',
    'BA in Acting',
    'BA Physical Theatre',
    'BA Professional Acting',
    'BA Professional Dance and Musical Theatre',
    'BA Voice in Performance',
    'BTEC Level 3 National Certificate in Performing Arts',
    'Diploma in Musical Theatre',
    'Diploma in Professional Acting',
    'Final Year Actors',
    'Final Year Musical Theatre',
    'Graduating Actors',
    'MA Acting',
    'MA Acting (International)',
    'MA Musical Theatre',
    'MA Screen Acting',
    'Masters 3 Year Diploma',
    'One Year Foundation Acting Course',
    'One Year Professional Acting Diploma',
    'Professional Acting',
    'Professional Dance',
    'Professional Performance',
    'Trinity Level 6 Diploma in Professional Musical Theatre',
  ],
})
