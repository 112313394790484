export default function ({ store, error, redirect }) {
  // Only allow industry users and admins past here
  if (!store.getters.isGraduate && !store.getters.isAdmin) {
    error({
      message: 'You are not a graduate user',
      statusCode: 403,
    })
    return redirect('/login/')
  }
}
