
import { mapState, mapGetters } from 'vuex'

export default {
  data() {
    return {
      items: [
        {
          text: 'Graduates',
          icon: 'mdi-account-box-outline',
          route: '/admin/graduates/',
        },
        { text: 'Schools', icon: 'mdi-school', route: '/admin/schools/' },
        { text: 'Helphub', icon: 'mdi-help', route: '/admin/helphub/' },
        { text: 'Users', icon: 'mdi-account-group', route: '/admin/users/' },
        {
          text: 'User Claims',
          icon: 'mdi-shield-crown',
          route: '/admin/claims/',
        },
        {
          text: 'AdminAuth',
          icon: 'mdi-security-network',
          route: '/admin/auth/',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      authUser: (state) => state.authUser,
    }),
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      emailVerified: 'emailVerified',
      isAdmin: 'isAdmin',
      isGraduate: 'isGraduate',
      isIndustry: 'isIndustry',
      allSchools: 'allSchools',
      graduateSchools: 'graduateSchools',
      regions: 'regions',
      schoolById: 'schoolById',
    }),
  },
}
