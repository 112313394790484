import { db } from '../plugins/firebase'
import { getDoc, doc } from 'firebase/firestore'

export default {
  // setUserMenuItems: (context, action) => {
  //   context.commit('setUserMenuItems', action)
  // },
  // deleteCreateProfileItem: (context) => {
  //   context.commit('deleteCreateProfileItem')
  // },
  // deleteGraduateProfileItem: (context) => {
  //   context.commit('deleteGraduateProfileItem')
  // },
  async getSchools({ commit }) {
    const docSnap = await getDoc(doc(db, 'schools', 'all-schools'))
    if (!docSnap.exists()) return
    const { schools } = docSnap.data()
    // Sort schools by name
    schools.sort((a, b) => (a.name < b.name ? -1 : 1))
    commit('SET_SCHOOLS', schools)
  },
  async setUser({ commit }, uid) {
    try {
      const docSnap = await getDoc(doc(db, 'users', uid))
      if (!docSnap.exists()) return
      const data = docSnap.data()
      if (!data.address) {
        data.address = {}
        data.address.name = ''
      }
      const user = data
      commit('setUser', user)
    } catch (error) {
      // error
    }
  },
}
