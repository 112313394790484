import { render, staticRenderFns } from "./default.vue?vue&type=template&id=078d556a&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Snackbar: require('/opt/buildhome/repo/components/utils/Snackbar.vue').default,TheNavbar: require('/opt/buildhome/repo/components/Navigation/TheNavbar.vue').default,TheFooter: require('/opt/buildhome/repo/components/Navigation/TheFooter.vue').default,TheBottomNavigation: require('/opt/buildhome/repo/components/Navigation/TheBottomNavigation.vue').default})
