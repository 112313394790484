
import { mapState, mapGetters } from 'vuex'
import { analytics, auth } from '~/plugins/firebase'
import { logEvent } from 'firebase/analytics'
import { signOut } from 'firebase/auth'

export default {
  data: () => ({
    loading: true,
    user: {},
    drawer: false,
    items: [
      { title: 'Home', icon: 'mdi-home', route: '/' },
      {
        title: 'Graduates',
        icon: 'mdi-account-multiple',
        route: '/graduates/',
      },
      { title: 'List of Schools', icon: 'mdi-school', route: '/schools/' },
    ],
    userDrawer: false,
    submenuInfo: [
      {
        title: 'Why Online Showcasing?',
        icon: 'mdi-format-list-bulleted',
        route: '/info/why-showcasing/',
      },
      { title: 'FAQs', icon: 'mdi-format-list-bulleted', route: '/info/faq/' },
      // { title: 'Pricing', icon: 'mdi-currency-usd', route: '/info/pricing/' },
      { title: 'Help Hub', icon: 'mdi-help', route: '/info/helphub/' },
      {
        title: 'Contact',
        icon: 'mdi-message-text-outline',
        route: '/info/contact/',
      },
    ],
    submenuAbout: [
      { title: 'Our Story', route: '/about/story/' },
      { title: 'Meet The Team', route: '/about/team/' },
      { title: 'Testimonials', route: '/about/testimonials/' },
      {
        title: 'Press',
        route: '/about/press/',
      },
    ],
  }),
  mounted() {
    this.user = this.userData
    this.loading = false
  },
  computed: {
    ...mapState({
      authUser: (state) => state.authUser,
      userData: (state) => state.user,
    }),
    ...mapGetters([
      'isLoggedIn',
      'userMenuItems',
      'isAdmin',
      'isGraduate',
      'isIndustry',
    ]),
  },
  methods: {
    logout() {
      try {
        signOut(auth)
        this.user = {}
        logEvent(analytics, 'logout')
        this.$router.push('/')
      } catch (e) {
        alert(e)
      }
    },
  },
}
